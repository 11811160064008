import clsx from 'clsx'
import Image from 'next/image'
import React from 'react'

import { ODSListItem } from '@/ts/types/general.types'

type Props = {
  ods?: ODSListItem
  onClick?: (odsIndex: number) => void
  isSelected: boolean
}

const OdsSelectorItem = ({ ods, onClick, isSelected }: Props): JSX.Element => {
  return (
    <button
      onClick={() => onClick && onClick(ods?.index)}
      className={clsx('', onClick ? 'pointer-events-auto' : 'pointer-events-none')}
    >
      <Image
        className={clsx('p-1 m-auto rounded-xl', isSelected ? 'bg-secondary' : 'bg-white')}
        width={120}
        height={120}
        alt={ods.name}
        src={ods.print}
        unoptimized
      />
    </button>
  )
}

export default OdsSelectorItem
