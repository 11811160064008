import { useRouter } from 'next/router'
import { useState } from 'react'
import toast from 'react-hot-toast'
import * as yup from 'yup'

import { SITE_URL } from '@/config'
import { useAuthStore } from '@/dataStores/auth'
import { updateUserByUUID } from '@/services/authenticationServices'
import { handleError } from '@/utils/alerts'

type ReturnProps = {
  handleUpdate: (values: { InterestODSs: string[] }) => void
  loading: boolean
  schema: yup.AnyObjectSchema
  handleStep1: (values: CompleteDataStep) => void
  step1Values: CompleteDataStep
  odsSchema: yup.AnyObjectSchema
}

type CompleteDataStep = {
  Address: string
  Country: string
  City: string
  Biography: string
  Latitude: number
  Longitude: number
}

const useCompleteProfile = (changeStep): ReturnProps => {
  const [loading, setLoading] = useState(false)
  const [step1Values, setStep1Values] = useState<CompleteDataStep>()

  const router = useRouter()
  const handleStep1 = async (values) => {
    setStep1Values(values)
    console.warn(values)
    changeStep(2)
  }

  const [user, loadFromStorage] = useAuthStore((state) => [state.user, state.loadFromStorage])

  const handleUpdate = async (values: { InterestODSs: string[] }) => {
    try {
      setLoading(true)
      const updatedUser = await updateUserByUUID(user?.UUID, {
        ...user,
        InterestODSs: values.InterestODSs,
        Biography: step1Values.Biography,
        Address: step1Values.Address,
        City: step1Values.City,
        Country: step1Values.Country,
        Latitude: step1Values.Latitude,
        Longitude: step1Values.Longitude,
      })
      toast.success(`Información actualizada con éxito.`)
      await loadFromStorage()
      if (user.GroupUUID) {
        router.push(`${SITE_URL}/groups/${user.GroupUUID}?onboard=true`)
      } else {
        router.push(`${SITE_URL}?onboard=true`)
      }
    } catch (e) {
      //Error handler cant update user
      handleError('cant update user', 'user')
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const schema = yup.object().shape({
    Address: yup.string().required(`Ubicación es requerida`),
  })

  const odsSchema = yup.object().shape({
    InterestODSs: yup.array().of(yup.string()).min(1, 'Se necesita al menos una ODS').required('Se necesitan las ODSs'),
  })

  return {
    handleUpdate,
    loading,
    schema,
    handleStep1,
    step1Values,
    odsSchema,
  }
}

export default useCompleteProfile
