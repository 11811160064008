import { useController } from 'react-hook-form'

import useLocationAutocompleteInput from '@/hooks/useLocationAutocompleteInput'

import CustomText, { FontWeight, TextSize } from './CustomText'
import { StaticMap } from './StaticMap'
import TextInput from './TextInput'

const MapInput = ({ name, control, placeholder }) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control })
  const { ref, value, onChange } = field

  const handleAddressChange = (value) => {
    onChange({
      Address: value.formattedAddress,
      Latitude: value.latitude,
      Longitude: value.longitude,
      Country: value.country,
      City: value.city,
    })
  }

  return (
    <div>
      <CustomText size={TextSize.SubTitle2} weight={FontWeight.SemiBold}>
        Seleccionar ubicación
      </CustomText>
      <button ref={ref} tabIndex={-1} className="pointer-events-none absolute h-0" />
      <AutocompleteInput
        value={value?.Address}
        onChange={handleAddressChange}
        error={error?.Address?.message}
        placeholder={placeholder}
      />

      <StaticMap latitude={value?.Latitude} longitude={value?.Longitude} />
    </div>
  )
}

export const AutocompleteInput = ({ onChange, value, error, options = {}, placeholder }) => {
  const {
    ref: autocompleteRef,
    value: innerValue,
    setValue: setInnerValue,
  } = useLocationAutocompleteInput({
    initialValue: value,
    onSelect: onChange,
    options,
  })
  return (
    <TextInput
      ref={autocompleteRef}
      value={innerValue}
      onChange={(e) => setInnerValue(e.target.value)}
      placeholder={placeholder || 'Ej: Av. Edmundo Eluchans 3000, Concón, Chile'}
      error={error}
    />
  )
}

export default MapInput
