import React from 'react'

import { odsList } from '@/utils/odsList'

import OdsSelectorItem from './OdsSelectorItem'

type Props = {
  selectedODS?: string[]
  onSelect?: (values: string[]) => void
  isSingleValue?: boolean
}

const OdsSelector = ({ selectedODS, onSelect, isSingleValue = false }: Props): JSX.Element => {
  const handleSingleSelection = (odsIndex: number) => {
    onSelect([`${odsIndex}`])
  }

  const handleSelection = (odsIndex: number) => {
    if (selectedODS?.includes(`${odsIndex}`)) {
      const newValues = selectedODS.filter((value) => value !== `${odsIndex}`)
      onSelect(newValues)
    } else {
      const newValues = [...selectedODS, `${odsIndex}`]
      onSelect(newValues)
    }
  }

  return (
    <div className="flex flex-col gap-5 h-96 overflow-y-scroll px-2 py-2 ">
      <div className="flex flex-wrap gap-2 justify-center">
        {odsList.map((ods) => (
          <OdsSelectorItem
            key={ods.index}
            ods={ods}
            onClick={isSingleValue ? handleSingleSelection : handleSelection}
            isSelected={!!selectedODS?.find((o) => o === `${ods.index}`)}
          />
        ))}
      </div>
    </div>
  )
}

export default OdsSelector
