import { googleAppsAPIKey } from '@/config'

export const StaticMap = ({ latitude, longitude }) => {
  const url = `https://www.google.com/maps/embed/v1/place?q=${latitude},${longitude}&key=${googleAppsAPIKey}`
  return (
    <div>
      <iframe src={url} width="100%" height="200" className="rounded-sm border-0 bg-gray" key={url} />
    </div>
  )
}
