import { useEffect, useRef, useState } from 'react'

export default function useLocationAutocompleteInput({ initialValue, onSelect, onClear, options = {} } = {}) {
  const [value, setValue] = useState('')
  const input = useRef(null)
  const autocomplete = useRef(null)
  const [innerSelected, setInnerSelected] = useState(null)

  // const options = {
  //   types: ['(cities)'],
  // }

  const handleSelect = (values) => {
    onSelect && onSelect(values)
    setInnerSelected(values)
    setValue(values.formattedAddress)
  }

  const handlePlaceSelect = () => {
    try {
      const place = autocomplete.current.getPlace()
      const latlng = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      const commune = place.address_components?.find((c) => c.types.includes('administrative_area_level_3'))?.long_name
      const communeFallback = place.address_components?.find((c) => c.types.includes('locality'))?.long_name

      const country = place.address_components?.find((c) => c.types.includes('country'))?.long_name
      handleSelect({
        formattedAddress: place.formatted_address,
        latitude: latlng.lat,
        longitude: latlng.lng,
        googlePlaceID: place.place_id,
        city: commune || communeFallback,
        country,
      })
    } catch (e) {
      console.log('Could not handle place selection', e)
    }
  }

  const handleClear = () => {
    if (innerSelected) {
      setValue('')
      setInnerSelected(null)
    }

    onClear && onClear()
  }

  useEffect(() => {
    if (input.current && !autocomplete.current) {
      autocomplete.current = new window.google.maps.places.Autocomplete(input.current, options)
      autocomplete.current.setFields(['formatted_address', 'geometry', 'place_id', 'address_components'])
      autocomplete.current.addListener('place_changed', handlePlaceSelect)
    }
  }, [input.current])

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue)
    }
  }, [initialValue])

  return { ref: input, handleClear, innerSelected, value, setValue }
}
