import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import { NextPage } from 'next'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import Container from '@/components/base/Container'
import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import LoadingWrapper from '@/components/base/LoadingWrapper'
import { AutocompleteInput } from '@/components/base/MapInput'
import TextArea from '@/components/base/TextArea'
import Avatar from '@/components/shared/Avatar'
import OdsSelector from '@/components/shared/OdsSelector/OdsSelector'
import { environment, VERSION_NUMBER } from '@/config'
import { useAuthStore } from '@/dataStores/auth'
import useCompleteProfile from '@/hooks/useCompleteProfile'

import Button, { ButtonAppearance, ButtonSize } from '../../components/base/Button'

type CompleteDataStep = {
  Biography: string
  Address: string
  City: string
  Country: string
  Latitude: number
  Longitude: number
}

type CompleteDataODSStep = {
  InterestODSs: string[]
}

const CompleteProfilePage: NextPage = () => {
  const { handleUpdate, loading, schema, handleStep1, odsSchema } = useCompleteProfile((step: number) => {
    setStep(step)
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<CompleteDataStep>({
    resolver: yupResolver(schema),
  })

  const {
    handleSubmit: handleSubmitODS,
    formState: { errors: errorsODS },
    setValue: setODSValue,
    watch,
  } = useForm<CompleteDataODSStep>({
    resolver: yupResolver(odsSchema),
  })
  const odsValues = watch('InterestODSs')

  const [step, setStep] = useState<number>(1)
  const [user] = useAuthStore((state) => [state.user])

  // const mappedCountries = AllCountryNames.map((item) => {
  //   return { value: item, label: item }
  // })

  const handleAddressChange = (location) => {
    setValue('Address', location.formattedAddress)
    setValue('City', location.city)
    setValue('Country', location.country)
    setValue('Latitude', location.latitude)
    setValue('Longitude', location.longitude)
  }

  return (
    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-4">
      <Container maxWidth={617} className="w-full">
        <LoadingWrapper loading={loading}>
          {step === 1 ? (
            <div className="w-50">
              <div className="flex flex-col  justify-center gap-2">
                <div className="flex flex-col  justify-center">
                  <CustomText className="text-center" size={TextSize.Heading1} weight={FontWeight.SemiBold}>
                    Completa tu perfil
                  </CustomText>
                </div>

                <div className="flex flex-row items-center gap-2">
                  <Avatar
                    text={`${user?.FirstName} ${user?.LastName}`}
                    className="w-14 h-14 text-white text-md !rounded-full border-primary border-2   "
                    image={user?.Photo?.URL}
                  />

                  <div className="flex flex-col">
                    <CustomText weight={FontWeight.SemiBold} className="text-gray">
                      {`${user?.FirstName} ${user?.LastName}`}
                    </CustomText>
                    <CustomText className="text-gray">{`${user?.Email}`}</CustomText>
                  </div>
                </div>

                <div className="flex flex-col mt-4 ">
                  <CustomText size={TextSize.SubTitle2} weight={FontWeight.SemiBold} className=" text-black">
                    Información General{' '}
                  </CustomText>
                </div>

                <div className="relative z-10 space-y-1">
                  <CustomText size={TextSize.SubTitle2} weight={FontWeight.SemiBold}>
                    Comuna
                  </CustomText>
                  <AutocompleteInput
                    value={user?.Address}
                    onChange={handleAddressChange}
                    error={errors?.Address?.message}
                    options={{ options: { types: ['(cities)'] } }}
                    placeholder="Comuna"
                  />
                </div>

                <TextArea error={errors?.Biography?.message} {...register('Biography')} placeholder="Biografía" />

                <Button
                  className="w-full"
                  size={ButtonSize.Big}
                  appearance={ButtonAppearance.Primary}
                  onClick={handleSubmit(handleStep1)}
                  block
                >
                  Continuar
                </Button>
              </div>{' '}
            </div>
          ) : null}

          {step === 2 ? (
            <div className="w-50">
              <div className="flex flex-col  justify-center gap-2">
                <div className="flex flex-col justify-center">
                  <CustomText className="text-center" size={TextSize.Heading1} weight={FontWeight.SemiBold}>
                    Completa tu perfil
                  </CustomText>
                </div>

                <div className="flex flex-row items-center gap-2">
                  <Avatar
                    text={`${user?.FirstName} ${user?.LastName}`}
                    className="w-14 h-14 text-white text-md !rounded-full border-primary border-2   "
                    image={user?.Photo?.URL}
                  />

                  <div className="flex flex-col">
                    <CustomText weight={FontWeight.SemiBold} className="text-gray">
                      {`${user?.FirstName} ${user?.LastName}`}
                    </CustomText>
                    <CustomText className="text-gray">{`${user?.Email}`}</CustomText>
                  </div>
                </div>

                <div className="flex flex-col  mt-4 ">
                  <CustomText size={TextSize.SubTitle2} weight={FontWeight.SemiBold} className=" text-black">
                    Selecciona los ODS de tu interés
                  </CustomText>
                </div>

                <OdsSelector selectedODS={odsValues || []} onSelect={(values) => setODSValue('InterestODSs', values)} />
                <CustomText
                  size={TextSize.Caption}
                  weight={FontWeight.Normal}
                  className={clsx(
                    'block text-red mb-2 truncate',
                    errorsODS?.InterestODSs?.message ? 'opacity-100' : 'opacity-0'
                  )}
                >
                  {errorsODS?.InterestODSs?.message || 'error'}
                </CustomText>

                <div className="ml-auto mt-4 w-full">
                  <Button
                    size={ButtonSize.Big}
                    appearance={ButtonAppearance.Primary}
                    onClick={handleSubmitODS(handleUpdate)}
                    block
                  >
                    Continuar
                  </Button>
                </div>
              </div>{' '}
            </div>
          ) : null}
          <div className="border border-t border-gray-3 w-full mt-8 mb-6" />

          <CustomText className="text-center " size={TextSize.Caption} weight={FontWeight.Normal}>
            {environment} Versión {VERSION_NUMBER || '0.0.0'}
          </CustomText>
        </LoadingWrapper>
      </Container>
    </div>
  )
}

CompleteProfilePage.requireAuth = true

export default CompleteProfilePage
